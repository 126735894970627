import React, { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contex/AuthContext";
import { useForm } from "react-hook-form";
import "./auth.css";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});
  const { dispatch } = useContext(AuthContext);

  const navigation = useNavigate();

  const handleLogin = async (data) => {
    const { password, email } = data;

    if (loading) {
      return;
    } else {
      setLoading(true);
      try {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            const { photoURL, displayName, uid } = user;
            const token = userCredential.accessToken;
            const allowedUserIds = [
              process.env.REACT_APP_ALLOW_A,
              process.env.REACT_APP_ALLOW_B,
              process.env.REACT_APP_ALLOW_C,
            ];

            if (allowedUserIds.includes(uid)) {
              dispatch({
                type: "LOGIN",
                payload: { photoURL, displayName, uid, token },
              });
              navigation("/");
            } else {
              setError("You are not authorized to perform this operation.");
            }
          })
          .catch((error) => {
            const errorCode = error.code;
            // const errorMessage = error.message;
            setError(errorCode);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="loginPage">
      <form onSubmit={handleSubmit(handleLogin)}>
        {errors.username && <p className="warn">{errors.username.message}</p>}

        <label>
          <input
            type="email"
            placeholder="Email"
            name="email"
            autoComplete="off"
            {...register("email", {
              required: "required*",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
        </label>
        {errors.email && <p className="warn">{errors.email.message}</p>}
        <label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            {...register("password", {
              required: "This is required*",
            })}
          />
        </label>
        {error && <p className="warnRED">{error}</p>}

        <label>
          <button type="submit" className="btnlgn">
            Login
          </button>
        </label>
      </form>
    </div>
  );
};

export default Login;
